<!--
--------------------------------------------------------------------------------
<copyright file="ManufacturingFigures.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('report.manufacturing_console.total_estimated_hours') }}</summary>
      <p class="text">{{ $t('widget_info_text.total_estimated_hours') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.manufacturing_console.total_executed_hours') }}</summary>
      <p class="text">{{ $t('widget_info_text.total_executed_hours') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.manufacturing_console.ratio_est_actual') }}</summary>
      <p class="text">{{ $t('widget_info_text.ratio_est_actual') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.manufacturing_console.delayPO/delayPOL') }}</summary>
      <p class="text">{{ $t('widget_info_text.delayPO_delayPOL') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.manufacturing_console.scrap_ratio') }}</summary>
      <p class="text">{{ $t('widget_info_text.scrap_ratio') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.manufacturing_console.estimated_capacity') }}</summary>
      <p class="text">{{ $t('widget_info_text.estimated_capacity') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.manufacturing_console.actual_capacity') }}</summary>
      <p class="text">{{ $t('widget_info_text.actual_capacity') }}</p>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class SalesFiguresInfo extends Vue {}
</script>
